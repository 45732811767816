import React, { Suspense, useContext, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import WebFont from 'webfontloader';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'jquery';
import 'popper.js';
import 'bootstrap/dist/js/bootstrap';

import './index.scss';
import * as serviceWorker from './serviceWorker';
import Loader from './pages/Loader';
import Context from './context';
import reducer from './reducer';
import ProtectedRoute from './ProtectedRoute';
// import PrivacyPolicy from './components/Service/PrivacyPolicy';
// import TermOfUse from './components/Service/TermOfUse';
// import Disclaimer from './components/Service/Disclaimer';
import PageTracking from './usePageTracking';
const Home = React.lazy(() => import('./pages/Home'));
const Admin = React.lazy(() => import('./pages/Admin'));
const About = React.lazy(() => import('./pages/About'));
const ContactUs = React.lazy(() => import('./pages/ContactUs'));
const Auth = React.lazy(() => import('./pages/Auth'));
const Products = React.lazy(() => import('./pages/Products'));
const ErrorPage = React.lazy(() => import('./pages/ErrorPage'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const HomeLoan = React.lazy(() => import('./pages/Services/HomeLoan'));
const PersonalLoan = React.lazy(() => import('./pages/Services/PersonalLoan'));
const MortgageLoan = React.lazy(() => import('./pages/Services/MortgageLoan'));
const BusinessLoan = React.lazy(() => import('./pages/Services/BusinessLoan'));
const PrivacyPolicy = React.lazy(() =>
  import('./components/Service/PrivacyPolicy')
);
const TermOfUse = React.lazy(() => import('./components/Service/TermOfUse'));
const Disclaimer = React.lazy(() => import('./components/Service/Disclaimer'));
const TrackApp = React.lazy(() => import('./pages/Tools/TrackApp'));
const CreditScore = React.lazy(() => import('./pages/Tools/CreditScore'));
const ApplicationForm = React.lazy(() => import('./pages/ApplicationForm'));
const VerifyEmail = React.lazy(() => import('./pages/Verify'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));
const Emicalculator = React.lazy(() => import('./pages/EMICalc'));
const Overdraft = React.lazy(() => import('./pages/Services/Overdraft'));
const LandingPage = React.lazy(() => import('./pages/Landing'));
const Land = React.lazy(() => import('./pages/Land'));
const Testimonial = React.lazy(() => import('./pages/Testimonial'));

AOS.init();

const Root = () => {
  const initialState = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  console.log({ state });
  WebFont.load({
    google: {
      families: ['Lato', 'Montserrat', 'Raleway']
    }
  });

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Context.Provider value={{ state, dispatch }}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/home" component={Home} />
            <Route path="/auth" component={Auth} />
            <Route path="/about" component={About} />
            <Route path="/contactus" component={ContactUs} />
            <ProtectedRoute path="/dashboard" component={Dashboard} />

            <Route
              path="/logout"
              render={rProps => <Auth {...rProps} defaultRoutine="logout" />}
            />

            <Route path="/products" component={Products} />
            <Route path="/loader" component={Loader} />
            <Route path="/homeloan/nri" component={(props) => (<HomeLoan isNri={true} {...props} />)} />
            <Route path="/personalloan/nri" component={(props) => (<PersonalLoan isNri={true} {...props} />)} />
            <Route path="/mortgageloan/nri" component={(props) => (<MortgageLoan isNri={true} {...props} />)} />
            <Route path="/homeloan" component={HomeLoan} />
            <Route path="/personalloan" component={PersonalLoan} />
            <Route path="/mortgageloan" component={MortgageLoan} />
            <Route path="/businessloan" component={BusinessLoan} />
            <Route path="/creditscore" component={CreditScore} />
            <Route path="/trackapp" component={TrackApp} />
            <Route path="/emicalculator" component={Emicalculator} />
            <Route path="/privacypolicy" component={PrivacyPolicy} />
            <Route path="/termsofuse" component={TermOfUse} />
            <Route path="/disclaimer" component={Disclaimer} />
            <Route path="/admin" component={Admin} />

            <Route path="/apply" component={ApplicationForm} />

            <Route path="/verify/:token" component={VerifyEmail} />
            <Route path="/reset/:token" component={ResetPassword} />
            <Route path="/emicalculator" component={Emicalculator} />
            <Route path="/overdraft" component={Overdraft} />
            <Route path="/lp" component={Land} />
            <Route path="/pl" component={LandingPage} />
            <Route path="/testimonial" component={Testimonial} />
            <Route path="/" component={ErrorPage} />
          </Switch>
          <PageTracking />
        </Context.Provider>
      </Router>
    </Suspense>
  );
};

const tagManagerArgs = {
  gtmId: 'GTM-MZGKVMM'
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.register();
