import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

const usePageTracking = ({ location }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("UA-204476380-1");
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [initialized, location]);

  return null;
};

export default withRouter(usePageTracking);